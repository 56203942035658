<template>
  <div
    style="margin-top: 1.1rem; z-index: -1; height: auto; overflow-x: hidden"
  >
    <iframe
      id="iframe"
      ref="iframes"
      :height="iframeHeight"
      scrolling="no"
      width="100%"
      :src="urls"
      @load="adjustIframe"
    ></iframe>
  </div>
  <common-footer />
</template>
<script>
import CommonFooter from "@components/footer.vue";
export default {
  components: {
    CommonFooter,
  },
  created() {
    this.urls = this.$route.query.url;
    // console.log("this.urls", this.urls);
  },
  data() {
    return {
      iframeHeight: "1000px",
    };
  },
  computed: {},
  methods: {
    adjustIframe() {
      this.iframeHeight =
        this.$refs.iframes.contentWindow.document.documentElement.scrollHeight;
    },
  },
};
</script>
<style lang="scss" scoped>
#iframe {
  border-top: none;
}
</style>
